.about {
  display: flex;
  flex-direction: column;
}
/* -------------------- Containers -------------------- */
.container3 {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 40px 10px 40px;
}

.container4 {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 40px 10px 40px;
}
/* -------------------------- Card 1 -------------------------- */

.about-card1 {
  flex-grow: 3;
  width: 40%;
  height: auto;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 12px 12px 24px #d9d9d9, -12px -12px 24px #ffffff;
  padding: 20px;
  margin: 20px;
}

.aboutcard1-content {
  width: 100%;
  margin-top: auto;
}

.aboutcard1-content h3 {
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  margin: 0px;
}

.aboutcard1-content p {
  font-size: 20px;
  color: #8e8e8e;
  margin: 0px;
  margin-top: 40px;
  padding: 10px 0px 10px 0px;
}

/* --------------------------  -------------------------- */

/* -------------------------- Card 2 -------------------------- */
.about-card2 {
  flex-grow: 1;
  width: 15%;
  height: 450px;
  border-radius: 20px;

  background: #faf0ff;
  box-shadow: inset 20px 20px 60px #d9d9d9, inset -20px -20px 60px #ffffff;
  padding: 20px;
  margin: 20px;
}

.aboutcard2-content {
  width: 100%;
  margin-top: auto;
}

.aboutcard2-content img {
  width: 100%;
  height: 400px;
  border-radius: 20px;

  object-fit: cover;
}

/* --------------------------  -------------------------- */

.about-card3 {
  flex-grow: 1;
  width: 15%;
  height: auto;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 12px 12px 24px #d9d9d9, -12px -12px 24px #ffffff;
  padding: 20px;
  margin: 20px;
}

.aboutcard3-content {
  width: 100%;
  margin-top: auto;
}

.aboutcard3-content h3 {
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  margin: 0px;
}

.aboutcard3-content p {
  font-size: 20px;
  color: #8e8e8e;
  margin: 0px;
  padding: 10px 0px 10px 0px;
}

.aboutcard3-content svg {
  cursor: pointer;
  border-radius: 50px;
  padding: 5px;
  background: #ffffff;
  box-shadow: 20px 20px 60px #d3c8d9, -20px -20px 60px #f8ebff;
  margin: 10px;
  color: black;
}

/* --------------------------  -------------------------- */
.about-card4 {
  flex-grow: 1;
  width: 15%;
  height: 300px;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 12px 12px 24px #d9d9d9, -12px -12px 24px #ffffff;
  padding: 20px;
  margin: 20px;
}

.aboutcard4-content {
  width: 100%;
  margin-top: auto;
}

.aboutcard4-content iframe {
  display: flex;
  margin: auto;
  width: 100%;
  height: 300px;
  border: 0;
}

/* --------------------------  -------------------------- */

.about-card5 {
  flex-grow: 1;
  width: 15%;
  height: 300px;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 12px 12px 24px #d9d9d9, -12px -12px 24px #ffffff;
  padding: 20px;
  margin: 20px;
}

.aboutcard5-content {
  width: 100%;
  margin-top: auto;
}

.aboutcard5-content h3 {
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  margin: 0px;
}

.aboutcard5-content ul {
  margin-top: 40px;
  padding: 0px;
}
.aboutcard5-content li {
  font-size: 20px;
  color: #8e8e8e;
  margin: 0px;
  padding: 5px 0px 0px 0px;
  list-style: none;
}

/* --------------------------  -------------------------- */

.about-card6 {
  flex-grow: 3;
  width: 17%;
  height: 300px;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 12px 12px 24px #d9d9d9, -12px -12px 24px #ffffff;
  padding: 20px;
  margin: 20px;
}

.aboutcard6-content {
  width: 100%;
  margin-top: auto;
}

.aboutcard6-content h3 {
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  margin: 0px;
}

.part-exp {
  margin-top: 40px;
}

.exp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  padding: 0px;
}

.name {
  width: 20%;
}

.year {
  margin: 0 auto;
}

.line {
  width: 30%;
  height: 2px;
  background: #d9d9d9;
  border-radius: 10px;
  margin: 5px 10px 5px 10px;
}

.aboutcard6-content p {
  font-size: 20px;
  color: #8e8e8e;
  margin: 0px;
  padding: 10px 0px 10px 0px;
}

@media screen and (max-width: 1360px) {
  .about-card1 {
    width: 100%;
  }

  .about-card2 {
    width: 30%;
    margin: 20px 10px 20px 10px;
  }

  .about-card3 {
    width: 30%;
    margin: 20px 10px 20px 10px;
  }

  .aboutcard2-content p {
    margin-top: 10px;
  }

  .about-card3 {
    width: 30%;
    margin: 20px 10px 20px 10px;
  }
}

@media screen and (max-width: 1200px) {
  .about-card6 {
    width: 100%;
  }
}

@media screen and (max-width: 730px) {
  .container3 {
    padding: 40px 10px 10px 10px;
  }

  .container4 {
    padding: 0px 10px 10px 10px;
  }

  .about1-content p {
    font-size: 18px;
  }

  .about2-content p {
    font-size: 15px;
  }

  .about-card2 {
    width: 30%;
  }
  .about-card3 {
    width: 30%;
  }
}

@media screen and (max-width: 630px) {
  .about-card2 {
    width: 100%;
  }
  .about-card3 {
    width: 100%;
  }

  .contact-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }

  .contact-links a,
  svg {
    margin-top: auto;
  }
}

@media screen and (max-width: 500px) {
  .about-card1 {
    margin: 0px 10px 10px 10px;
  }

  .about-card2 {
    margin: 20px 10px 10px 10px;
  }

  .about-card3 {
    margin-bottom: 10px;
  }

  .about-card4,
  .about-card5 {
    width: 100%;
  }

  .cont-skills {
    width: 100%;
    height: 150px;
    border-radius: 30px;
    padding: 10px;
    margin: 0px 10px 0px 10px;
  }

  .aboutcard2-content p {
    font-size: 18px;
  }
}

@media screen and (max-width: 400px) {
  .line {
    width: 5%;
  }

  .aboutcard6-content p {
    font-size: 15px;
  }
}
