.projects-list {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 40px 10px 40px;
}

.project-card1 {
    flex-grow: 3;
    width: 40%;
    height: auto;
    border-radius: 20px;
    background: #ffffff;
    box-shadow: 12px 12px 24px #d9d9d9, -12px -12px 24px #ffffff;
    padding: 20px;
    margin: 20px;

  }
  
  
  
  .projectcard1-content {
      width: 100%;
      margin-top: auto;
  }
  
  .projectcard1-content h3{
      font-size: 30px;
      font-weight: bold;
      color: #000000;
      margin: 0px;
  }
  
  .projectcard1-content p {
      font-size: 20px;
      color: #8e8e8e;
      margin: 0px;
      margin-top: 40px;
      padding: 10px 0px 10px 0px;
  }
  

  @media screen and (max-width: 730px) {
    .projects-list {
      padding: 40px 10px 10px 10px;
      margin: 0px;
    }

    

  }

@media screen and (max-width: 800px) {
    .project-card1 {
      width: 100%;
      margin: 0px 10px 10px 10px
    }
  
    
  }