header {
  display: flex;
  /* justify-content: end; */
  align-items: center;
  padding: 10px;
}

nav {
  width: 300px;
  
  margin: 20px 0px 20px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  

  border-radius: 50px;

  
  /* background: #f8ebff;
box-shadow: inset 20px 20px 60px #d3c8d9,
            inset -20px -20px 60px #ffffff; */
            background: #faf0ff;
            box-shadow: inset 20px 20px 60px #d9d9d9,
                        inset -20px -20px 60px #ffffff;
}

.links {
  padding: 12px;
  margin: 5px;
  display: flex;
  align-items: center;
  font-size: 16px;
  text-decoration: none;
  font-weight: bold;
  color: black;
}

.links:hover {
  /* background-color: aliceblue; */

  border-radius: 20px;
  color: black;

  border-radius: 50px;
  background: #ffffff;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}

.activeLink {
  /* background-color: aliceblue; */

  padding: 12px;
  border-radius: 20px;
  margin: 5px;
  display: flex;
  align-items: center;
  font-size: 16px;
  text-decoration: none;
  font-weight: bold;
  color: black;

  border-radius: 50px;
  background: #ffffff;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}


/* .dark-mode {
  background-color: #424242;
  color: #fff;
} */


@media screen and (max-width: 450px) {

  header {
    justify-content: center;
  }
  nav {
    width: 250px;
    margin: 20px 10px 20px 10px;

    justify-content: center;
    align-items: center;
  }

  .links {


  }
}