.cardp {
  flex-grow: 1;
  width: 20%;
  height: auto;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 12px 12px 24px #d9d9d9, -12px -12px 24px #ffffff;
  padding: 20px;
  margin: 20px;
}

.cardp-content {
  width: 100%;
}

.cardp-infos h3 {
  font-size: 25px;
  font-weight: bold;
  color: #000000;
  margin-top: 10px;
}

.cardp-infos p {
  font-size: 20px;
  color: #8e8e8e;
  margin-top: 20px;
  margin-top: 20px;
}

.linkp {
  font-size: 15px;
  color: #8e8e8e;

  text-decoration: none;
}

.cardp-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media screen and (max-width: 800px) {
  .cardp {
    width: 30%;
  }
}

@media screen and (max-width: 600px) {
  .cardp {
    width: 100%;
    margin: 10px 10px 10px 10px;
  }
}


