.home {
  display: flex;
  flex-direction: column;
}
/* -------------------- Containers -------------------- */
.container1 {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 40px 10px 40px;
}

.container2 {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 40px 10px 40px;
}
/* -------------------------------------------------------- */

/* -------------------- Cards -------------------- */
.long-card1 {
  flex-grow: 3;
  width: 20%;
  height: auto;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 12px 12px 24px #d9d9d9, -12px -12px 24px #ffffff;
  padding: 20px;
  margin: 20px;
  display: flex;
  flex-direction: column;
}

.long-card2 {
  flex-grow: 3;
  width: 20%;
  height: auto;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 12px 12px 24px #d9d9d9, -12px -12px 24px #ffffff;
  padding: 20px;
  margin: 20px;
  display: flex;
  flex-direction: column;
}

.small-card1 {
  flex-grow: 1;
  width: 5%;
  height: 250px;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 12px 12px 24px #d9d9d9, -12px -12px 24px #ffffff;
  padding: 20px;
  margin: 20px;
}

.small-card2 {
  flex-grow: 1;
  width: 5%;
  height: auto;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 12px 12px 24px #d9d9d9, -12px -12px 24px #ffffff;
  padding: 20px;
  margin: 20px;
}

.small-card3 {
  flex-grow: 1;
  width: 5%;
  height: 250px;
  border-radius: 20px;
  background: #faf0ff;
  box-shadow: inset 20px 20px 60px #d9d9d9, inset -20px -20px 60px #ffffff;
  padding: 20px;
  margin: 20px;
}

/* -------------------------------------------------------- */

/* -------------------- Cards content -------------------- */

.longcard1-content {
  width: 100%;
  margin-top: auto;
}

.longcard1-content h1 {
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  margin: 0px;
}

.longcard1-content p {
  font-size: 20px;
  color: #8e8e8e;
  margin: 0px;
  padding: 10px 0px 10px 0px;
}

.longcard2-content {
  width: 100%;
}

.longcard2-content h3 {
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  margin: 0px 0px 20px 20px;
}

.longcard2-content p {
  text-align: center;
  font-size: 20px;
  color: #8e8e8e;
  margin: 0px;
  padding: 10px 0px 10px 0px;
}

.longcard-part2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cont-skills {
  width: 60%;
  height: auto;
  border-radius: 20px;
  background: #faf0ff;
  box-shadow: inset 20px 20px 60px #d9d9d9, inset -20px -20px 60px #ffffff;
  padding: 20px;
  margin: 0px 20px 0px 20px;
}

.longcard2-content img {
  display: flex;
  width: 150px;
  height: 150px;
  margin-left: auto;
  padding-right: 20px;
}

.smallcard1-content {
  width: 100%;
  margin-top: auto;
}

.small-card1 img {
  display: flex;
  width: 150px;
  height: 150px;
  margin-left: auto;
  padding-right: 20px;
}

.part2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.smallcard1-content h3 {
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  margin: 0px;
  margin-top: 15px;
}

.smallcard1-content p {
  display: flex;
  font-size: 20px;
  color: #8e8e8e;
  margin: 0px;
  padding: 10px 0px 10px 0px;
}

.smallcard1-content svg {
  border-radius: 50px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 20px 20px 60px #d3c8d9, -20px -20px 60px #f8ebff;
}

.smallcard2-content {
  width: 100%;
}

.smallcard2-content h3 {
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  margin: 0px;
}

.smallcard2-content p {
  display: flex;
  font-size: 20px;
  color: #8e8e8e;
}

.contact-links {
  display: flex;
}
.smallcard2-content svg {
  cursor: pointer;
  border-radius: 50px;
  padding: 5px;
  background: #ffffff;
  box-shadow: 20px 20px 60px #d3c8d9, -20px -20px 60px #f8ebff;
  margin: 10px;
  color: black;
}

.smallcard2-content a {
  width: 50px;
  height: 50px;
}

.smallcard3-content {
  width: 100%;
}

.video {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 20px;
} 

.lucide-chevron-right {
  cursor: pointer;
}

/* -------------------------------------------------------- */



@media screen and (max-width: 1360px) {
  .long-card1 {
    width: 100%;
  }

  .long-card2 {
    width: 100%;
  }

  .small-card1 {
    width: 30%;
    margin: 20px 10px 20px 10px;
  }

  .small-card2 {
    width: 30%;
    margin: 20px 10px 20px 10px;
  }



  .smallcard2-content p {
    margin-top: 10px;
  }

  .small-card3 {
    width: 30%;
    margin: 20px 10px 20px 10px;
  }
}

@media screen and (max-width: 730px) {
  .container1 {
    padding: 40px 10px 10px 10px;
  }

  .container2 {
    padding: 0px 10px 10px 10px;
  }


  .small-card1 {
    width: 30%;
  }

  .small-card2 {
    width: 30%;
  }
  .small-card3 {
    width: 30%;
  }
}



@media screen and (max-width: 600px) {
}

@media screen and (max-width: 500px) {
  .long-card1 {
    margin: 0px 10px 10px 10px;
  }

  .long-card2 {
    margin: 20px 10px 10px 10px;
  }

  .small-card1 {
    width: 100%;
    margin-bottom: 10px;
  }

  .small-card2 {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px !important;
  }

  .small-card3 {
    width: 100%;
    margin-bottom: 10px;
  }

  .longcard2-content img {
    display: none;
  }

  .cont-skills {
    width: 100%;
    border-radius: 30px;
    padding: 10px;
    margin: 0px 10px 0px 10px;
  }


}

@media screen and (max-width: 350px) {
  .long-card1 {
    display: block;
  }
}
